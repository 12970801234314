
export default {
  props: {
    isH3: {
      type: Boolean,
      default: false,
    },
    titleAlt: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    faq: {
      type: Array,
      default: () => [
        {
          question:
            "Est-il possible de modifier mon site internet ALEO après sa réalisation ?",
          answer: `<p class="pb-4">Nous comprenons que votre entreprise est en constante évolution. Chez ALEO, <strong>nous concevons votre site internet</strong> et effectuons des modifications de contenu de manière ponctuelle en fonction de vos besoins.</p>
  <p class="pb-4">Que vous ayez besoin de rafraîchir certaines pages de votre site, d'ajuster votre contenu, ou de changer une image, notre équipe d'éditeurs de site est à votre service pour effectuer ces modifications. Avec un <strong>design responsive</strong>, votre site s'adapte parfaitement aux <strong>ordinateurs, tablettes et mobiles.</strong> </p>
  <p>Nous souhaitons que votre <strong>site internet</strong> reste une <strong>vitrine</strong> pertinente et efficace, alignée avec l'évolution de votre entreprise.</p>`,
          isCollapsed: false,
        },
        {
          question: "Quel est le prix de la création d’un site web ?",
          answer: `<p class="pb-4">Le <strong>coût de la conception d’un site web</strong> avec ALEO peut fluctuer en fonction de vos besoins spécifiques. En fonction de votre <strong>stratégie digitale</strong>, du <strong>nombre de pages</strong> de votre site, et de l'<strong>optimisation</strong> pour le référencement que vous souhaitez, le prix peut varier.</p>
  <p>Pour toute demande concernant nos tarifs, nous vous invitons à cliquer sur le bouton "recevoir nos tarifs" ou à prendre rendez-vous avec un de nos conseillers.`,
          isCollapsed: false,
        },
        {
          question:
            "Pour la refonte de mon site internet, puis-je me tourner vers ALEO ?",
          answer: `<p class="pb-4">En tant qu'<strong>agence web professionnelle</strong>, ALEO est à votre disposition pour vous accompagner dans la <strong>refonte de votre site existant</strong>. Si vous avez déjà créé un site internet dont vous souhaitez garder le contenu, nos experts en <strong>développement web</strong> s'occuperont de cette transformation.</p>
  <p>Nous prenons en charge la récupération des éléments que vous souhaitez conserver pour votre nouveau site, et nous nous engageons à réaliser une transition fluide entre votre ancien et votre <strong>nouveau site web</strong>. Le but est de conserver votre image de marque tout en apportant les améliorations nécessaires à son optimisation.</p>`,
          isCollapsed: false,
        },
        //       {
        //         question: "Qu’est-ce que le référencement naturel ?",
        //         answer: `<p class="pb-4">Le <strong>référencement naturel</strong>, aussi connu sous le nom de SEO, est l'ensemble des techniques visant à améliorer la position de votre site internet dans les <strong>résultats de recherche Google</strong>. Autrement dit, il s'agit d'optimiser la visibilité de votre site internet de manière organique, sans avoir à recourir à la publicité payante.</p>
        // <p class="pb-4">Le référencement naturel comprend <strong>l'optimisation</strong> de différents aspects de votre site web, tant au niveau <strong>technique</strong> que du <strong>contenu</strong>. Cela inclut l'utilisation de mots-clés pertinents, l'optimisation de la structure du site, l'amélioration de la <strong>vitesse de chargement</strong>, la création de contenus de qualité et le développement de <strong>liens externes</strong> organiques.</p>
        // <p class="pb-4">L'objectif principal du référencement naturel est d'améliorer la <strong>visibilité</strong> d'un site web dans les <strong>résultats de recherche</strong>, ce qui permet d'attirer un trafic qualifié et d'augmenter le nombre de visiteurs. En se classant parmi les <strong>premiers résultats pour des mots-clés pertinents</strong>, un site a plus de chances d'être visité et de générer du trafic organique.</p>
        // <p>Le référencement naturel est un processus continu qui nécessite une surveillance et une adaptation constantes aux <strong>algorithmes des moteurs de recherche</strong>. Il peut également prendre du temps pour obtenir des résultats significatifs, car le classement d'un site web dépend de nombreux facteurs et de la concurrence existante sur ces mots-clés.</p>`,
        //         isCollapsed: false,
        //       },
        //       {
        //         question:
        //           "Proposez-vous une recherche de mots clés et une optimisation de contenu ?",
        //         answer: `<p class="pb-4">Pour chaque site internet que nous développons, nous mettons en place une <strong>stratégie de référencement</strong>. Lors de la prise de brief, nous prenons le temps de comprendre votre activité et vos besoins pour identifier les <strong>mots-clés</strong> les plus pertinents pour votre <strong>référencement</strong>.</p>
        // <p>Un <strong>rédacteur</strong> dédié rédige ensuite le contenu de votre site internet, en optimisant l'utilisation de ces mots-clés. Enfin, le développeur web en charge de la réalisation de votre site web assure une <strong>optimisation technique</strong> pour qu'il soit conforme aux critères de référencement de Google.</p>`,
        //         isCollapsed: false,
        //       },
        //       {
        //         question:
        //           "Fournissez-vous des rapports de performance SEO réguliers ?",
        //         answer: `<p class="pb-4">Absolument ! Si vous choisissez ALEO pour <strong>développer votre site internet</strong> professionnel, vous aurez accès à un tableau de performances vous permettant de suivre l'évolution de votre positionnement SEO et l'état de santé de votre site internet.</p>
        // <p>De plus, un expert dédié à votre projet sera toujours disponible pour vous accompagner et assurer un suivi optimal de votre projet web.</p>`,
        //         isCollapsed: false,
        //       },
        {
          question:
            "Pourquoi faire appel à une agence de création de sites web comme ALEO ?",
          answer: `<p class="pb-4"><strong>Créer un site internet</strong> à l'aide d'une <strong>agence</strong> comme ALEO offre de nombreux avantages. Notre objectif principal est d'accompagner votre entreprise tout au long de son <strong>évolution digitale</strong>. Ainsi, nous veillons à ce que votre site internet reste à jour et pertinent en permanence.</p>
  <p class="pb-4">Nos équipes sont prêtes à effectuer toutes les <strong>modifications</strong> nécessaires à votre <strong>contenu, images</strong>, et même assurer la <strong>sécurité</strong> de votre site avec un certificat <strong>SSL</strong>. Nous nous occupons également de l'hébergement web de votre site sur des <strong>serveurs sécurisés</strong>, 100 % français.</p>
  <p>En outre, ALEO est là pour vous aider dans la <strong>refonte de votre site</strong> existant. Si vous avez déjà un site internet et que vous souhaitez conserver vos contenus, nos équipes d'experts en <strong>développement de sites web</strong> s'occupent de tout.</p>`,
          isCollapsed: false,
        },
        {
          question:
            "Proposez-vous des designs personnalisés ou utilisez-vous des templates ?",
          answer: `<p class="pb-4">Chez ALEO, chaque <strong>création de site web</strong> est un projet unique. Nos <strong>sites vitrine</strong> sont 100 % personnalisés et adaptés à votre activité. Nous prenons en compte vos préférences pour l'organisation de vos <strong>textes</strong>, le design des <strong>éléments</strong>, les <strong>couleurs</strong> que vous souhaitez mettre en avant, et toute votre identité visuelle.</p>
  <p>Chaque <strong>conception de site web</strong> est réalisée en parfaite adéquation avec les besoins et les attentes de chaque client. C'est pourquoi, dès le début de chaque projet, un expert ALEO organise une prise de brief pour mieux cerner vos attentes.</p>`,
          isCollapsed: false,
        },
      ],
    },
  },
  data() {
    return {
      isDesktop: false,
    };
  },

  mounted() {
    this.watchMedia();
  },

  methods: {
    watchMedia() {
      window.matchMedia("(min-width: 1024px)").matches
        ? (this.isDesktop = true)
        : (this.isDesktop = false);
    },
  },
};
